import { render, staticRenderFns } from "./NamingNBranding-los-angeles.vue?vue&type=template&id=52bb3401&scoped=true"
import script from "./NamingNBranding-los-angeles.vue?vue&type=script&lang=js"
export * from "./NamingNBranding-los-angeles.vue?vue&type=script&lang=js"
import style0 from "./NamingNBranding-los-angeles.vue?vue&type=style&index=0&id=52bb3401&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bb3401",
  null
  
)

export default component.exports